// LIBRARIES
import styled, { css } from "styled-components";
//MISC
import { ButtonType, IconProps } from "./ButtonAtomModel";

import {
  COLORS,
  MARGIN,
  DEVICE_SIZE,
  BORDER_RADIUS,
} from "constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const Button = styled.button<{
  type?: keyof typeof ButtonType;
  biggerCursor?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${MARGIN.max10} ${MARGIN.max16};
  border: none;
  border-radius: ${BORDER_RADIUS.radius21};
  transition: 0.3s background-color ease-out;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  ${({ type }) =>
    type === ButtonType.X &&
    css`
      background-color: ${COLORS.WhiteSmoke};
      border-radius: ${BORDER_RADIUS.radius18};
      aspect-ratio: 1/1;
      height: 48px;
      width: 48px;
      &:hover {
        background-color: ${COLORS.Paua}!important;
        transition: 0.5s ease-in;
      }
    `}
  ${({ type }) =>
    type === ButtonType.MapActive &&
    css`
      background-color: ${COLORS.Cinnabar};
      &:hover {
        background-color: ${COLORS.Cinnabar}!important;
        transition: 0.5s ease-in;
      }
    `}
  ${({ type }) =>
    type === ButtonType.Audio &&
    css`
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: 48px;
      background-color: ${COLORS.WhiteSmoke};
      &:hover {
        background-color: ${COLORS.Solitude};
        transition: 0.3s ease-in;
      }
    `}
  ${({ type }) =>
    type === ButtonType.ArrowButton &&
    css`
      background-color: ${COLORS.Solitude};
      &:hover {
        background-color: ${COLORS.Paua};
        transition: 0.3s ease-in;
      }
    `}
  ${({ type }) =>
      type === ButtonType.PauseButton &&
      css`
      background-color: ${COLORS.Solitude};
      &:hover {
        background-color: ${COLORS.Paua};
        transition: 0.3s ease-in;
      }
    `}
  ${({ type }) =>
    type === ButtonType.RedButton &&
    css`
      width: 100%;
      height: 100%;
      background-color: ${COLORS.Cinnabar};
      &:hover {
        background-color: ${COLORS.MandarianOrange};
        transition: 0.3s ease-in;
      }
    `}
  ${({ type }) =>
    type === ButtonType.ChatButton &&
    css`
      margin-right: -10px;
      padding: 10px;
    `}
  ${({ type }) =>
    type === ButtonType.ManagementButton &&
    css`
      background-color: ${COLORS.BlackRussian};
      &:hover {
        /* background-color: ${COLORS.Paua}; */
        background-color: ${COLORS.Paua};

        transition: 0.3s ease-in;
      }
    `}

  ${({ type }) =>
    type === ButtonType.ArrowDown &&
    css`
      background-color: #77191a;
      &:hover {
        background-color: #991f1f;
        transition: 0.3s ease-in;
      }
    `}

  ${({ type }) =>
    type === ButtonType.Link &&
    css`
      background-color: ${COLORS.Solitude};
      padding: ${MARGIN.max8} ${MARGIN.max12};
      @media (max-width: ${DEVICE_SIZE.MOBILE}) {
        padding: 8px;
      }
      &:hover {
        background-color: ${COLORS.Paua};
        transition: 0.3s ease-in;
      }
    `};

  &:disabled {
    background-color: ${COLORS.Paua};
    color: ${COLORS.WhiteSmoke};
    cursor: not-allowed;
  }
`;

export const Icon = styled.img<IconProps>`
  width: ${({ type }) =>
    type === ButtonType.ArrowButton
      ? "12px"
      : type === ButtonType.ChatButton
      ? "18px"
      : "16px"};
  height: ${({ type }) =>
    type === ButtonType.ArrowButton
      ? "12px"
      : type === ButtonType.ChatButton
      ? "18px"
      : "18px"};
  margin: 0 5px;
`;
