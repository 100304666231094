function transliterateUkrainian(text) {
    const translitMap = {
        'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'H', 'Ґ': 'G', 'Д': 'D', 'Е': 'E', 'Є': 'Ye', 'Ж': 'Zh', 'З': 'Z',
        'И': 'Y', 'І': 'I', 'Ї': 'Yi', 'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N', 'О': 'O', 'П': 'P',
        'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U', 'Ф': 'F', 'Х': 'Kh', 'Ц': 'Ts', 'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Shch',
        'Ю': 'Yu', 'Я': 'Ya',
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'h', 'ґ': 'g', 'д': 'd', 'е': 'e', 'є': 'ie', 'ж': 'zh', 'з': 'z',
        'и': 'y', 'і': 'i', 'ї': 'yi', 'й': 'i', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o', 'п': 'p',
        'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'kh', 'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'shch',
        'ю': 'iu', 'я': 'ia'
    };

    return text.split('').map(char => translitMap[char] || char).join('');
}

export default function modRewrite(utf8String,id = null) {
    const romanianDiacritics = ['a','t','a','a','i','s','t','A','A','I','S','T'];
    ['ă','ţ','ă', 'â', 'î', 'ș', 'ț', 'Ă', 'Â', 'Î', 'Ș', 'Ț']?.forEach((el: string,index)=>{
      utf8String = utf8String?.replace(el,romanianDiacritics[index]);
    })
    utf8String = transliterateUkrainian(utf8String);
    if(id !== null) {
        return utf8String?.toLowerCase()?.split(" ")?.join("-") + `-${id}`;
    } else {
        return utf8String?.toLowerCase()?.split(" ")?.join("-")
    }
  }

  
