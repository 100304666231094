export interface ButtonAtomProps {
  text?: string;
  leftIcon?: boolean;
  icon?:any,
  rightIcon?: boolean;
  type?: ButtonType;
  onClick?: (any) => void;
  disabled?: boolean;
}

export interface IconProps {
  type?: ButtonType.ArrowButton | ButtonType.Audio;
}

export enum ButtonType {
  ArrowButton,
  Audio,
  RedButton,
  Link,
  MapActive,
  X,
  ChatButton,
  ArrowDown,
  ManagementButton,
  PauseButton
}
