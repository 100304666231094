import { useEffect, useState } from "react";

// ASSETS
import { ReactComponent as DefaultIcon } from "assets/svg/DefaultIcon.svg";

// STYLES
import {
  GuidesPageContainer,
  GuidesPageTitleWrapper,
  DropdownWrapper,
  GuidesPageContentWrapper,
  GuidesCardWrapper,
  CirclesWrapper,
  GuidesPageWrapper,
  GuidesPageNoGuidesTextWrapper,
} from "./GuidesStyled";

// LIBRARIES
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import guideService from "services/guideService";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
// MISC
import { baseUrl } from "../../services/base";
import { CardType } from "components/Atoms/GuideCardAtom/GuideCardAtomModel";
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
// REDUX
// COMPONENTS
import GuideCardAtom from "components/Atoms/GuideCardAtom/GuideCardAtom";
import DropdownAtom from "components/Atoms/DropdownAtom/DropdownAtom";
import CirclesAtom from "components/Atoms/CirclesAtom/CirclesAtom";
import CustomText from "components/Atoms/CustomText/CustomText";
import ImgAtom from "components/Atoms/ImgAtom/ImgAtom";
import modRewrite from "../../locales/modRewrite";
const Guides = () => {
  // PROPERTIES
  // LIBRARY CONSTANTS

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const sizes = isMobile
    ? ["500px", "400px", "300px", "200px", "100px"]
    : ["900px", "720px", "550px", "400px", "200px"];
  const colors = {
    firstColor: COLORS.WhiteSmoke,
    secondColor: COLORS.Solitude,
  };
  const guidesSkeleton = new Array(8).fill(null);

  // STATE CONSTANTS
  const [categories, setCategories] = useState<any[]>([]);
  const [guides, setGuides] = useState<any[]>([]);
  const [filter, setFilter] = useState<string[]>([]);
  // API REQUESTS
  // LIFE CYCLE
  useEffect(() => {
    (async () => {
      let response: any = await guideService.getGuidesCategories();
      setFilter(response.data.map((el: any) => el.id));
      setCategories(
        response.data.map((el: any) => ({
          label: el.attributes.title,
          value: el.id,
        }))
      );
      response = await guideService.getGuides();

      if (response.data !== null) {
        const tmpGuides = response.data.map((el: any) => ({
          id: el.id,
          title: el.attributes.title,
          category: el.attributes.guides_category?.data?.id,
          icon:
            el.attributes.icon.data == null ? (
              <DefaultIcon />
            ) : (
              <ImgAtom
                img={`${baseUrl}${el.attributes.icon.data.attributes.url}`}
                label={el.attributes.title}
                height={SIZE.max100}
              />
            ),
        }));
        setGuides(tmpGuides);
      }
    })();
  }, []);
  // EVENT HANDLERS
  const handleOnClick = (event) => {
    navigate("/ghiduri/" + modRewrite(event.title, event.id));
  };

  const handleKeyPress = (event, card) => {
    if (event.key === "Enter") {
      handleOnClick(card);
    }
  };

  return (
    <GuidesPageContainer>
      <CirclesWrapper>
        <CirclesAtom
          sizes={sizes}
          colors={colors}
          containerTopPosition="100%"
          containerRightPosition="100%"
        />
      </CirclesWrapper>
      <GuidesPageWrapper>
        <GuidesPageTitleWrapper>
          <CustomText
            text={t("view.guides.title")}
            type={TextType.H1}
            color={COLORS.BlackRussian}
          />
          <DropdownWrapper>
            <DropdownAtom
              options={categories}
              title={t("view.guides.category")}
              dropdownName={t("view.guides.category")}
              onConfirm={(values) => {
                if (values.length > 0) {
                  setFilter(values.map((el: any) => el.value));
                } else {
                  setFilter(categories.map((el: any) => el.value));
                }
              }}
            />
          </DropdownWrapper>
        </GuidesPageTitleWrapper>
        <GuidesPageContentWrapper>
          {guides.length > 0
            ? guides
                .filter((el: any) => filter.includes(el.category))
                .map((card) => (
                  <GuidesCardWrapper
                    tabIndex={0}
                    key={`${card.title}-guide-card`}
                    onKeyDown={(e) => handleKeyPress(e, card)}
                  >
                    <GuideCardAtom
                      id={card.id}
                      title={card.title}
                      icon={card.icon}
                      cardType={CardType.GuideStyle}
                      height={isMobile ? SIZE.max160 : SIZE.max320}
                    />
                  </GuidesCardWrapper>
                ))
            : guidesSkeleton.map((_, index) => (
                <GuidesCardWrapper
                  tabIndex={0}
                  key={`skeleton-guide-card-${index}`}
                >
                  <Skeleton
                    width={"100%"}
                    height={"100%"}
                    baseColor={COLORS.WhiteSmoke}
                    highlightColor={COLORS.Solitude}
                    borderRadius={BORDER_RADIUS.radius24}
                  />
                </GuidesCardWrapper>
              ))}
          {guides.length > 0 &&
            guides.filter((el: any) => filter.includes(el.category)).length ===
              0 && (
              <GuidesPageNoGuidesTextWrapper>
                <CustomText
                  text={"Această categorie nu are ghiduri în acest moment."}
                  type={TextType.H2}
                  color={COLORS.BlackRussian}
                />
              </GuidesPageNoGuidesTextWrapper>
            )}
        </GuidesPageContentWrapper>
      </GuidesPageWrapper>
    </GuidesPageContainer>
  );
};

export default Guides;
